@use "../fonts";
@use "../breakpoints";
@use "../colors";

.page-banner{
  margin: 90px 0 0;

  // Match height of header as header is now fixed - doing this here saves js doing it
  @media (min-width: breakpoints.$lg){
    margin-top: 126px;
  }
}

.page-banner__inner-container{
  display: flex;
  flex-direction: column;
  background: rgba(colors.$fadingGrey,0.5);
}
.page-banner__caption{
  text-align: center;
  color: #fff;
  padding: 1em;
}

.page-banner__caption-wrapper{
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  min-height: 275px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: breakpoints.$lg){
    min-height: 530px;
  }
}

.page-banner__image{
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  @supports not (background-attachment: fixed){
    background-attachment: scroll;
  }
}

.page-banner__heading{
  @include fonts.font-bold;
  @media (min-width: breakpoints.$lg){
    font-size: 3em;
  }
}