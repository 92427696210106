// Generate responsive max-widths in 50px increments

$number-of-variants: 20;


@for $size from 1 through $number-of-variants {
  .max-width-#{($size * 100) * 0.5}{
    max-width: (($size * 100) * 0.5)* 1px;
  }
}

@each $break-point-name, $break-point-width in $grid-breakpoints {
  @media (min-width: $break-point-width) {
    @for $size from 1 through $number-of-variants {
      .max-width-#{$break-point-name}-#{($size * 100) * 0.5}{
        max-width: (($size * 100) * 0.5)* 1px;
      }
    }
  }
}