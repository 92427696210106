@use "../breakpoints";

.home-banner {
    position: relative;
    color: #fff;
    overflow: hidden;
    margin: 111px 0 0;
    // Match height of header as header is now fixed - doing this here saves js doing it
    @media (min-width: breakpoints.$lg) {
        margin-top: 160px;
    }

    img {
        width:100%;
    }

    .slick-arrow {
        font-size: 0;
        line-height: 0;
        display: block;
        width: 32px;
        height: 32px;
        background: none;
        border: 0px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 88;
    }

    .slick-prev {
        background: url('../images/prev-arrow.png') no-repeat center center;
        background-size: 32px;
        left: 3%;
    }

    .slick-next {
        background: url('../images/next-arrow.png') no-repeat center center;
        background-size: 32px;
        right: 3%;
    }
    /*  &:after{
    content: "";
    background-color: rgba(#000,0.4);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 20;
  }*/
}

.home-banner__inner-container {
    min-height: 490px;
    position: relative;
    z-index: 30;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    padding: 7em 2em;
    max-width: 600px;
    margin: 0 auto;

    @media (min-width: breakpoints.$lg) {
        min-height: 550px;
        max-width: 1080px;
    }

    @media (min-width: 1600px) {
        min-height: 850px;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        height: 490px;

        @media (min-width: breakpoints.$lg) {
            height: 550px;
        }

        @media (min-width: 1600px) {
            height: 850px;
        }
    }
}

.home-banner__video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 10;
    min-width: 100%;
    min-height: 100%;
}

.home-banner__heading{
  @media (min-width: breakpoints.$lg){
    font-size: 3.2em;
  }

  @media (min-width: breakpoints.$xl){
    font-size: 3.8em;
  }
}

.home-banner__play-button-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    &.is-active {

        .home-banner__pause-button {
            display: flex;
        }

        .home-banner__play-button {
            display: none;
        }
    }
}


.home-banner__pause-button-icon,
.home-banner__play-button-icon{
  font-size: 1.8em;
  margin: 0 0 0 0.5em;
}

.home-banner__pause-button,
.home-banner__play-button{
    border:0;
    background: none;
    color: inherit;
    justify-content: center;
    align-items: center;
    width: 100%;
  padding: 2em;
  &:focus{
      outline: none;
  }
}

.home-banner__play-button {
    display: flex;
}

.home-banner__pause-button {
    display: none;
}