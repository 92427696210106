@use "../colors";

.bg-marine-grey{
  background-color: colors.$marineGrey;
}


.bg-overlay {
    position: relative;

    &:after {
        content: "";
        //background-color: rgba(colors.$metalBlack, 0.5);
        background-color: rgba(82, 94, 102, 0.7);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
    }
}


.bg-overlay--light-grey{
  &:after{
    background-color: rgba(82, 94, 102, 0.7);
  }
}

.bg-overlay--mid-grey {
    &:after{
        background-color: rgba(colors.$metalBlack, 0.4);
    }
}

.bg-overlay-dark-grey {
    &:after{
        background-color: rgba(colors.$metalBlack, 0.6);
    }
}

.bg-overlay__content{
  position: relative;
  z-index: 20;
}


.bg-image-cover{
    background-position:center;
    background-size:cover;
    background-repeat: no-repeat;
}