@use "../breakpoints";

.border-lg-right-white{
  @media (min-width: breakpoints.$lg){
    border-right: 1px solid #fff;
  }
}

.border-bottom-white{
  border-bottom: 1px solid #fff;
}


.border-lg-bottom-none{
  @media (min-width: breakpoints.$lg){
    border-bottom: 0;
  }
}