@import "config";
@import "../node_modules/bootstrap/scss/bootstrap";
@import "components/navigation";
@import "components/social-icon";
@import "components/header";
@import "components/page-banner";
@import "components/banner";
@import "components/line-break";
@import "components/typestyles";
@import "components/footer";
@import "components/backgrounds";
@import "icons";
@import "components/navigation-icon";
@import "components/navigation-button";
@import "components/get-in-touch";
@import "flexible-ratios";
@import "spacing";
@import "components/our-services-list";
@import "components/link-hover-effects";
@import "components/post-preview";
@import "components/post-preview-grid";
@import "components/job-preview";
@import "max-widths";
@import "components/border";
@import "components/home-banner";
@import "font-size";
@import "components/news-slider";
@import "components/field-validation-error";
@import "components/js-scroll-header-trigger";
@import "components/body";
@import "components/btn";
@import "components/_similar-projects-slider.scss";

/* Gallery Crop Fix */
.gal-mobile {
    display: block;
}
.gal-desktop {
    display: none;
}
@media (min-width: 768px) {
    .gal-mobile {
        display: none;
    }

    .gal-desktop {
        display: block;
    }
}