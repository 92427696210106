@use "../colors";

.btn--is-active{
  position: relative;
  &:after {
    content: "";
    width: 50%;
    height: 3px;
    display: block;
    background-color: colors.$spaceGrey;
    position: absolute;
    top:100%;
    left: 50%;
    transform: translateX(-50%);
  }
}