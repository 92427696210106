@use "../breakpoints";

.our-services-list{
  margin: 0;
  list-style-type: none;
  padding: 0;
  @media (min-width: breakpoints.$lg){
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

  }

  li{
    line-height: 35px;
    @media (min-width: breakpoints.$lg){
        line-height: 47px;
    }
    &:after{
      @media (min-width: breakpoints.$lg) {
        content: "|";
        margin: 0 0.5em;
      }
    }
    &:last-of-type{
      &:after {
        @media (min-width: breakpoints.$lg) {
          display: none;
        }
      }
    }
  }
}

.our-services-list--home{
  @media (min-width: breakpoints.$xl){
    font-size: 1.3em;
  }
}