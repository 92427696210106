@mixin GenerateFlexibleRatios() {

  $ratio-map: (
    1:1,
    4:3,
    3:1,
    653:262,
    1306:363,
    569:524,
    619:581,
    418:343,
    1307:481,
    642:481
  );


  .ratio{
    position: relative;
  }


  @each $first, $second in $ratio-map {
    .ratio--#{$first}-#{$second} {
      &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: ($second / $first) * 100%;
      }

      .ratio__inner {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

  }

  @each $break-point-name, $break-point-width in $grid-breakpoints {
    @media (min-width: $break-point-width) {
      @each $first, $second in $ratio-map {
        .ratio--#{$break-point-name}-#{$first}-#{$second} {

          &:before {
            display: block;
            content: "";
            width: 100%;
            padding-top: ($second / $first) * 100%;
          }

          .ratio__inner {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
          }
        }
      }
    }
  }
}

@include GenerateFlexibleRatios;