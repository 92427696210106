@use "../colors";

.navigation-icon{
  width: 30px;
  span{
    background-color: colors.$spaceGrey;
    height: 2px;
    display: block;
    &:nth-of-type(2){
      margin: 6px 0;
    }
  }
}