@use "../breakpoints";

.post-preview-grid{
  margin: 0;
  @media (min-width: breakpoints.$md){
    margin: 0 -0.1em;
  }
  @media (min-width: breakpoints.$lg){
    margin: 0 -1em;
  }
}

.post-preview-grid__item{
  padding: 0 0 0.2em;
  margin: 0;
  @media (min-width: breakpoints.$md){
    padding: 0 0.1em 0.2em;
  }
  @media (min-width: breakpoints.$lg){
    padding: 0 1em 2em;
  }
}