@use "../fonts";
@use "../colors";
@use "../breakpoints";

.footer{
  text-align: center;
  padding: 2em 0;
  @media (min-width: breakpoints.$lg){
    text-align: left;
    display: flex;
    flex-wrap: wrap;
  }
}

.footer__link{
  color:inherit;
}

.footer__info{
  border-top: 2px solid colors.$metalBlack;
  margin: 2em 0 0 0;
  padding: 2em 0 0;
  width: 100%;
  @media (min-width: breakpoints.$lg) {
    display: flex;
  }
}

.footer__author-link{
  @media (min-width: breakpoints.$lg){
    margin-left: auto;
  }
}

.footer__logo-container{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  @media (min-width: breakpoints.$lg){
    justify-content: space-between;
  }
}

.footer__logo{
  width: 50%;
  padding: 0.5em;
  @media (min-width: breakpoints.$md){
    width: 33.33%;
  }
  @media (min-width: breakpoints.$lg){
    width: auto;
    padding: 0;
  }
}

.footer__col{
  @media (min-width: breakpoints.$lg) {
    width: 25%;
  }
}

.footer__link-separator{
  &:after {
    content: "|";
    display: inline-block;
    @media (min-width: breakpoints.$lg) {
      content: ",";
    }
  }
}

.footer__link--info{
  @media (min-width: breakpoints.$lg){
    text-decoration: underline;
  }
}