@use "../fonts";

.get-in-touch{
  text-align: center;
  padding: 3em 0;
  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.get-in-touch__btn{

}

.get-in-touch__heading{
  text-transform: uppercase;
  @include fonts.font-bold;
  font-size: 1.4em;
  margin: 0 0 1.6em;
  @media (min-width: 1024px) {
    margin: 0 2em 0 0;
  }
}