.js-scroll-header-trigger{
  position: absolute;
  top: 200px;
  left:0;
  pointer-events: none;
  /*
  background-color: red;
  height: 10px;
  width: 100%;

   */
}