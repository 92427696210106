@use "../colors";
@use "../icons";

.social-icon{
  height: 1.7em;
  width: 1.7em;
  background-color: #fff;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9em;
  color: colors.$spaceGrey;

  &:before{
    @include icons.icon-font;
  }
}

.social-icon--dark{
  background-color: colors.$spaceGrey;
  color: #fff;
}

.social-icon--footer{
  font-size: 1.4em;
}

.social-icon--twitter{
  &:before{
    @include icons.twitter-icon;
    font-size: 0.7em;
  }
}

.social-icon--facebook{
  &:before{
    @include icons.facebook-icon;
  }
}

.social-icon--instagram{
  &:before{
    @include icons.instagram-icon;
  }
}

.social-icon--linked-in{
  &:before{
    @include icons.linked-in-icon;
    font-size: 0.8em;
  }
}

.social-icon--pinterest{
  &:before{
    @include icons.pinterest-icon;
  }
}

.social-icon--houzz{
  &:before{
    @include icons.houzz-icon;
    font-size: 0.8em;
  }
}