﻿@use "../breakpoints";
@use "../colors";
@use "../fonts";

.banner--page {
    margin: 90px 0 0;

    // Match height of header as header is now fixed - doing this here saves js doing it 
    @media (min-width: breakpoints.$lg) {
        margin-top: 126px;
    }
}

.banner {
    /*margin: 0;*/
    overflow: hidden;
}

.banner__picture {
}

.banner--page {
    position: relative;
    max-width: 2000px;
    margin-left: auto;
    margin-right: auto;
}

.banner__caption {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
}

.banner__inner-caption {
    max-width: 1000px;
    padding: 2em;
    color: #fff;
    text-align: center;
}

.banner__image {
    width: 100%;
    max-width: 100%;
}


.banner__heading {
    @include fonts.font-bold;

    @media (min-width: breakpoints.$lg) {
        font-size: 3em;
    }
}