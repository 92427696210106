@font-face {
  font-family: 'icons';
  src:  url('fonts/icons.eot?ltzqlr');
  src:  url('fonts/icons.eot?ltzqlr#iefix') format('embedded-opentype'),
  url('fonts/icons.woff2?ltzqlr') format('woff2'),
  url('fonts/icons.ttf?ltzqlr') format('truetype'),
  url('fonts/icons.woff?ltzqlr') format('woff'),
  url('fonts/icons.svg?ltzqlr#icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@mixin icon-font(){
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin video-pause(){
    content: "\e909";
}

@mixin close-icon(){
  content: "\e908";
}
@mixin video-icon(){
    content: "\e907";
}
@mixin houzz-icon(){
  content: "\e900";
}
@mixin pinterest-icon(){
  content: "\e901";
}
@mixin instagram-icon(){
  content: "\e902";
}

@mixin linked-in-icon(){
  content: "\e903";
}
@mixin twitter-icon(){
  content: "\e904";
}

@mixin facebook-icon(){
  content: "\e905";
}
@mixin map-pin-icon(){
  content: "\e906";
}

.icon-close:before{
  @include icon-font;
  @include close-icon;
}

.icon-map-pin:before{
  @include icon-font;
  @include map-pin-icon;
}

.icon-video:before{
  @include icon-font;
  @include video-icon;
}

.icon-pause-video:before{
    @include icon-font;
    @include video-pause;
}