﻿@use "../colors";

.similar-projects-slider {
    .slick-dots{
        display: flex;
        align-items: center;
        justify-content:center;
        margin: 0;
        padding: 1em;
        width: 100%;
        list-style-type: none;
        li{
            height: 0.6em;
            width: 0.6em;
            margin: 0 0.2em;
            border-radius: 100%;
            background: colors.$spaceGrey;
            &.slick-active{
                transform: scale(1.2);
            }
        }
        button {
            display: none;
        }
    }
}