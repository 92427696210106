@use "../colors";
@use "../fonts";
@use "../breakpoints";

@keyframes slide-in{
  from {
    transform: translateX(-100%);
  }
  to{
    transform: translateX(0);
  }
}

.navigation{
  background-color: colors.$spaceGrey;
  color: #fff;
  max-width: 570px;
  display: none;
  transform: translateX(-100%);
  position: fixed;
  top:0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  overflow-y: auto;
  font-size: 5.5vw;
  @media (min-width: 500px){
    font-size: 1.5em;
  }
}

.navigation--open{
  display: block;
  animation-duration: 300ms;
  animation-name: slide-in;
  transform: translateX(0);
}

.navigation__link--small{
  font-size: 0.9em;
}

.navigation__link{
  color: #fff;
  display: block;
  line-height: 1;
}

.navigation__link--main{
  @include fonts.font-bold;
  text-transform: uppercase;
  &:hover{
    text-decoration: none;
  }
  @media (min-width: breakpoints.$lg){
    font-size: 1.3em;
  }
}

.navigation__container{
  max-width: 70%;
  margin: 0 auto;
  @media (min-width: 500px){
      max-width: 50%;
  }
}

.navigation__link-container{
  border-bottom: 2px solid #fff;
  max-width:90%;
}

.navigation__close{
  border: 0;
  background: transparent;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 1.5em;
  font-size: 0.8em;
  i{
    margin: 0 0.8em 0 0;
    font-size: 0.7em;
  }
}