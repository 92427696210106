
@mixin font-copy {
  font-family: century-gothic, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin font-bold{
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin font-bold-italic{
  font-family: century-gothic, sans-serif;
  font-weight: 700;
  font-style: italic;
}

@mixin font-copy-italic{
  font-family: century-gothic, sans-serif;
  font-weight: 400;
  font-style: italic;
}

$body-font: century-gothic, sans-serif;

@mixin body-font-weight{
  font-weight: 400;
}