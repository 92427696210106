@use "../breakpoints";

.navigation-button{
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background: transparent;
  @media (min-width: breakpoints.$lg){
    font-size: 1.2em;
  }
}