.link-hover-effect-1{
  color: inherit;
  &:hover {
    text-decoration: none;
   img {
     filter: grayscale(100%);
   }
  }
}

.link-hover-effect-2{
  color: inherit;
  &:hover{
    text-decoration: none;
  }
}