@use "../colors";
@use "../breakpoints";

.job-preview{
  border-bottom: 3px solid colors.$skyGrey;
  @media (min-width: breakpoints.$md){
    display: flex;
    justify-content: space-between;
  }
}

.job-preview__line{
    @media (min-width: breakpoints.$md){
        width: 22.5%;
    }
}

.job-preview__line--first {
    @media (min-width: breakpoints.$md) {
        width: 35%;
        padding: 0 1em 0 0;
    }
}

.job-preview__line--last{
    @media (min-width: breakpoints.$md){
        text-align: right;
        width: 10%;
    }
}