@use "../colors";

.line-break{
  background-color: colors.$grassGreen;
  height: 3px;
  width: 89px;
  max-width: 100%;
  margin: 0;
  display: inline-block;
  border: 0;
}

.line-break--white{
  background-color: #fff;
}