@use "../fonts";
@use "../colors";

.ts-bold{
  @include fonts.font-bold;
}

.ts-not-bold{
  @include fonts.body-font-weight;
}

.text-grassGreen{
  color: colors.$grassGreen;
}
.text-spaceGrey{
  color: colors.$spaceGrey;
}
.text-marineGrey{
  color: colors.$marineGrey;
}
.text-metalBlack{
  color: colors.$metalBlack;
}
.text-carGrey{
  color: colors.$carGrey;
}