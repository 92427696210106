@use "fonts";
@use "colors";
@use "breakpoints";

$theme-colors:(
  "primary": colors.$grassGreen,
);

$btn-border-radius: 0;
$btn-padding-x: 1.5em;
$btn-padding-y: 0.6em;
$btn-font-weight: 700;
$font-family-base: fonts.$body-font;
$body-color: colors.$metalBlack;
$headings-font-weight: 700;
$spacer : 1rem;
$grid-columns: 24;
$input-border-radius: 0;
$input-border-width: 0;
$input-padding-y: 1rem;
$input-padding-x: 1.5rem;

$container-max-widths: (
 sm: 540px,
 md: 750px,
 lg: 960px,
 xl: 1338px
);

$grid-breakpoints: (
  xs: breakpoints.$xs,
  sm: breakpoints.$sm,
  md: breakpoints.$md,
  lg: breakpoints.$lg,
  xl: breakpoints.$xl
);

$spacers:(
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: ($spacer * .75),
    4: $spacer,
    5: ($spacer * 1.25),
    6: ($spacer * 1.5),
    7: ($spacer * 1.75),
    8: ($spacer * 2),
    9: ($spacer * 2.25),
    10: ($spacer * 2.5),
    11: ($spacer * 2.75),
    12: ($spacer * 3),
    13: ($spacer * 3.25),
    14: ($spacer * 3.5),
    15: ($spacer * 3.75),
    16: ($spacer * 4),
    17: ($spacer * 4.25),
    18: ($spacer * 4.5),
    19: ($spacer * 4.75),
    20: ($spacer * 5),
    21: ($spacer * 5.25),
    22: ($spacer * 5.5),
    23: ($spacer * 5.75),
    24: ($spacer * 6),
    25: ($spacer * 6.25),
    26: ($spacer * 6.5),
    27: ($spacer * 6.75),
    28: ($spacer * 7),
    29: ($spacer * 7.25),
    30: ($spacer * 7.5),
    31: ($spacer * 7.75),
    32: ($spacer * 8)
);


img{
  max-width: 100%;
}