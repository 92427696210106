@use "../colors";

.news-slider.slick-initialized{

  .news-slider__caption{
    padding: 3em 1em 1em !important;
  }

  .slick-dots{
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
    position: absolute;
    top: 190px;
    left: 50%;
    transform: translateX(-50%);

    li{
      height: 0.6em;
      width: 0.6em;
      margin: 0 0.2em;
      border-radius: 100%;
      background: colors.$spaceGrey;

      &.slick-active{
        transform: scale(1.2);
      }
    }

    button{
     display: none;
    }
  }
}