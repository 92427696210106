@use "./breakpoints";


@for $i from 0 through 10 {
  @for $j from 0 through 10 {
    .font-size-#{$i}-#{$j}{
      font-size: ($i + ($j / 10)) * 1em;
    }
  }
}

@each $break-point-name, $break-point-width in $grid-breakpoints {
  @media (min-width: $break-point-width) {
    @for $i from 0 through 10 {
      @for $j from 0 through 10 {
        .font-size-#{$break-point-name}-#{$i}-#{$j}{
          font-size: ($i + ($j / 10)) * 1em;
        }
      }
    }
  }
}