@use "../colors";
@use "../breakpoints";

.post-preview{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  &:hover{
    text-decoration: none;
    .post-preview__image{
      filter: grayscale(100%);
      &:after{
        opacity: 1;
      }
    }
    .post-preview__content{
      opacity: 1;
    }
  }
}

.post-preview__content{
  z-index: 20;
  text-align: center;
  color: #fff;
  transition: opacity 300ms;
  padding: 0.5em;

  @media (min-width: breakpoints.$lg){
    opacity: 0;
  }
}

.post-preview__image:after,
.post-preview__image{
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.post-preview__image{
  margin:0;
}

.post-preview__image:after{
  content: "";
  background-color: rgba(82, 94, 102, 0.8);
  z-index: 15;
  transition: opacity 300ms;
  @media (min-width: breakpoints.$lg){
    opacity: 0;
  }
}