@use "../breakpoints";

.header{
  position: fixed;
  top:0;
  left: 0;
  right: 0;
  z-index: 90;
  background-color:#fff;
}

.header__container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2em 0;
  @media (min-width: breakpoints.$lg){
    padding: 3em 0;
  }
}

.header__logo{
  max-width: 300px;
  width: 50%;
}

.header__logo-img{
  width: 100%;
}