@use "../breakpoints";

.body__footer{
  @media (min-width: breakpoints.$lg) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }
}

.body__content{
  position: relative;
  z-index: 90;
  background-color: #fff;
}